body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color:rgb(36, 41, 47)
  }
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 600 !important;
    color:#394157;
}
.bg-gray{
  background-color:#eaedf0   ;
}
  /* NavBar */
a.nav-link {
  font-size:0.9rem;
  padding-left:0.4em!important;
  padding-right:0.4em!important;
  padding-top: 0.2rem;
	padding-bottom: 0.3em!important;
  margin-left: 2em;
	display: inline-block;
	text-decoration: none;
	background-image: linear-gradient(#64a4db, #64a4db);
  transition: all 550ms cubic-bezier(.45,1,.32,1);
	background-position: 0% 100%;
	background-repeat: no-repeat;
	background-size: 0% 2.5px;
}
a.nav-link.active {
	background-image: linear-gradient(#64a4db, #64a4db);
	background-size: 100% 2.5px!important;
	cursor: default;
  color:#fff
}
a.nav-link:focus, a.nav-link:hover {
	background-size: 100% 2.5px;
}
.navbar-dark .navbar-nav .nav-link {
color:rgba(255, 255, 255, 0.8)!important;
}
#topnav{
box-shadow:0 2px 5px rgba(0,0,0,.26);
z-index:999
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
  }
/* OffCanvas */
#offcanvasNavbar a,#offcanvasNavbar div{
  margin-left: 0em;
  font-size: 1.1rem;
}

.mainpage{
 min-height: 79vh;
 display: block;
 position: relative;
}
/* Breadcumb*/
.breadcrumb{
  background-color: transparent!important;
}
.breadcrumb a{
  color: #4c65c7;
  font-weight: 600;
}
.breadcrumb a:hover{
  color: #225499;
  text-decoration: underline;
}

/* Footer */
footer{
  background-color:#bbcffc;
  position:relative;
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}
.list-group-item{
background-color: transparent!important;
}
.footerlink.nav-link{
  margin-left: 0em;
  font-size:1rem;
  padding-top:0.5rem;
}
.nav-link.footerlink:hover,.nav-link.footerlink.active{
color:#000!important
}

/* TabComponent */
.nav-tabs .nav-link,.nav-tabs .nav-link:hover,.nav-tabs .nav-link.active,.nav-tabs a.nav-link:focus,.nav-tabs .nav-link.active:hover{
  background-color: rgba(255, 255, 255, 0)!important;
  margin-left:0;
  margin-right:0;
  padding-left:1.8em!important;
  padding-right:1.8em!important;
  border-top:rgba(255, 255, 255, 0)!important;
  border-left:rgba(255, 255, 255, 0)!important;
  border-right:rgba(255, 255, 255, 0)!important;
  line-height:30px;
  color: #495057;
  background-image: none;
}
.nav-tabs .nav-link.active,.nav-tabs a.nav-link:focus,.nav-tabs .nav-link.active:hover{
  border-bottom: 2px solid #f9826c!important;
  font-weight: 500;
  cursor: default;
  color:#000;
}
.nav-tabs .nav-link:hover{
  border-bottom: 2px solid #c0c0c0!important;
  color:#000;
}
.bgfull{
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  margin-bottom: -1px;
}
.nav-item .counter{
  background-color:#d0d0d0 ;
  font-weight:400;
  margin-left: 0.4em;
}

.table .progress{
  height:1.25rem;
}
.progress-bar {
  overflow:visible!important;
  white-space:nowrap!important;
  color:rgb(29, 29, 29)!important;
}

/**** Bootstrap Table ****/
.react-bootstrap-table table {
  table-layout: auto;
}

.table > :not(:first-child) {
  border-top: 0px!important;
}
.react-bootstrap-table td, .react-bootstrap-table td a{
font-weight:600;
vertical-align: middle!important;
color:#525f7f;
text-decoration: none;
}
.react-bootstrap-table th{border-top: 0!important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.76rem;
  color:#485269;
  box-sizing: border-box;
  }

.freeze{
white-space: normal;
background-color: #fff!important;
background-clip: padding-box;
position: sticky;
left: 0;
}
.table-hover tbody tr:hover td, .table-hover tbody tr:hover td a{
color: #212529;
background-clip: padding-box;
}
.tr:hover a{
text-decoration: underline;}
.toupdate{
color:#536081;
}
.updated td table{color: #525f7f;
font-weight:700;
animation: change 1.5s step-end both;
}
@keyframes change {
0%  {color:#4d76c2!important;}
90%  {color:rgb(39, 39, 39); }
}

.coinlogo{
  margin-top: 0.5rem;
  height: 66px;
  width: 66px;
}
.searchad{
  height: 35px;
  width: 35px;
  margin-right:0.8em;
}
.searchbarlogo{
  height: 20px;
  width: 20px;
  margin-right:0.5em;
}

.tablelogo{
  height: 25px;
  width: 25px;
  margin-right:0.5em;
}
.exchangelogo{
  height: 45px;
  width: 45px;
}
.sponsored{
  color: #3160d5;
  background-color: #bbcffc;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

tbody .small{font-size:90%}


/* APEXCHARTS */
.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: white!important;
  font-weight: bold;
}

#chartContainer .apexcharts-tooltip {
  color: #000000;
  overflow:visible;
}

#chart-control .btn{
font-weight: 500;
}
#chart-control .btn-light:hover{
    box-shadow: 0 0!important;
    background-color:#dce0e4!important;
}
#chart-control .btn-light.active{
  box-shadow: 0 0!important;
  background-color:#dae0e5!important;
}

.monthcase:first-letter {
  text-transform: uppercase;
}

/* Search */
#navSearch .searchbar {
  width: 17rem;
}

.searchdropdown{
  background-color:#fff;
  z-index: 9999;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  position:absolute;
  top:75%;
  flex-direction: column;
  margin: 0px;
  margin-left:0.02rem;
  width: 17rem;
  padding-top:1em;
  padding-bottom:1rem;
  max-height: 18.3rem;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top:0px!important;
  line-height: 1.8;
}
.searchdropdown .nav-link{
margin-left:0px!important;
font-weight:600;
background-image:none;
color:#325b8f;
}
.searchdropdown a.nav-link{
  padding-left:1rem!important;
}

#searchbar-dropdown a.nav-link:hover, #searchbar-dropdown .nav-link:active,  #searchbar-dropdown .nav-link:focus{
  background-color:#e9ecef!important;
}
#searchbar-dropdown::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5f5;
}
#searchbar-dropdown::-webkit-scrollbar-track {
  border-radius: 10px;
}
#searchbar-dropdown::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 10px;
}

#offcanvasNavbar .searchdropdown{
  top: 11rem;
}

input[type="search"] {
  outline: none;
  box-shadow:none !important;
  border:1px solid #ccc !important;
  }
  input[type="search"]:focus {
    border:1px solid #ccc !important;
    }

#offcanvasNavbar .searchbar,#offcanvasNavbar .searchdropdown {
  width: 20rem;
}
.about p{
  font-size:100%
}
.about .alert-info{
  border-width: 0px 0px 0px 5px;
}
.about .alert-heading{
  font-size:125%;
}
.about li{
  padding:0.5em; font-size:95%; font-family:inherit;
}
.codeblock{
  background-color:rgb(35, 35, 39);
  color:rgb(117, 191, 255)
}
.int{color:rgb(134, 222, 116)}
.string{color:rgb(255, 125, 233)}

.maintext{
  font-weight:500;
  font-size:16px;
}

.card#feed:hover, .card a:hover{
background-color:rgb(247, 247, 247);
text-decoration: none;
}
.card#feed:hover{
  transform: scale(1.005); }

  /* LivecoinsBar*/
#livecoinsbar {
  font-size:98%;
max-height: 40px;
padding: 0;
vertical-align: middle;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
}
#livecoinsbar .nav-link {
  color:black;
  margin-left: 2em;
  font-weight: 600;
  background-image: none;
}
#livecoinsbar .nav-link:hover{
  color:#db4e5a;
}
#livecoinsbar .dropdown-item{font-size:82%;  font-weight: 600;}
#livecoinsbar .dropdown-item:hover{
  background-color:white;
  color:#db4e5a;
}
.iconslive{
  margin: 0!important;
}

.icontable{
  transform: scale(0.7);
  vertical-align:middle
}
.icon-rotated{
  transform: scale(0.7) rotate(180deg);
}
.icon-rotated-offcanvas{
  transform: scale(0.7) rotate(90deg);
}

.btn:hover{
  box-shadow: 0 4px 6px 0 rgba(0,0,0,.1),0 1px 10px 0 rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.1)!important;
}
.btn.btn-border-2{
  border: 2px solid;
  font-weight: 500;
}

.back-to-top-wrapper {
  position: absolute;
  z-index: 10;
  top: 86vh;
  bottom: 5em;
  width: 3em;
  pointer-events: none;
}

.scrolltotop{
  position: sticky;
  bottom:30px;
  width: 40px;
  height: 40px;
  padding: 16px 16px;
  pointer-events: all;
  top: calc(100vh - 5rem);

}
.scrolltotop.fade{
  transition: all 1s;
}

.openmarket-table td{
  padding:0.2rem!important;
}

/* MOBILE */
@media only screen and (max-device-width: 800px) {
  /* For mobile phones: */
  .back-to-top-wrapper {
    right: 1em;
    bottom: 3em;
  }
  .symbolmarket-responsive{
    margin-left:1.5rem!important;
  }
  .scrolltotop{
    right:10px;
  }
}