// change the theme
$theme-colors: (
  "primary": #3954ab,
  "warning": #ff9800 ,
  "info": #39c0ed,
  "light":#f8f9fa,
  "secondary": #2f78e2,
  "success": #1eb980,
  "greenlime": #11af30, // etoro ad //
  "danger":  #f93154,
  "gray":  #8d8f91,
  "dark": #262626,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import './index.css'